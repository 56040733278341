export const BG_PRIMARY = '#cba981';
export const BG_PRIMARY_GRADIENT = '#785731';
export const BG_SECONDARY = '#F4F1E9';

export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = '#cba981';

export const BUTTON_COLOR_TEXT = '#000';
export const QR_BORDER_COLOR = '#a8865e';

// Icon & Navigation
export const BG_NAVIGATION = `#fff`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;

// DEFAULT STYLES
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
