import { extendTheme } from '@chakra-ui/react';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  BUTTON_COLOR_TEXT,
  BG_PRIMARY_GRADIENT,
  QR_BORDER_COLOR,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    subHeading_lv2: FONT_FAMILY.subHeading_lv2,
    body: FONT_FAMILY.body,
    name: FONT_FAMILY.name,
    fullName: FONT_FAMILY.fullName,
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '48px',
    subHeading: '20px',
    subHeading_lv2: '36px',
    body: '16px',
    name: '60px',
    fullName: '20px',
  },
  fontWeights: {
    heading: 400,
    subHeading: 400,
    subHeading_lv2: 400,
    body: 400,
    name: 400,
    fullName: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-subHeading_lv2': '',
        '--fontItalic-body': '',
        '--fontItalic-name': '',
        '--fontItalic-fullName': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgPrimaryGradient: BG_PRIMARY_GRADIENT,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    btnMainColor: BUTTON_COLOR_TEXT,
    borderColorQR: QR_BORDER_COLOR,
    transparent: 'transparent',
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
  },
};

export default extendTheme(theme);
