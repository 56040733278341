/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `BiankaScript, cursive;`,
  subHeading: `Philosopher, cursive;`,
  subHeading_lv2: `BiankaScript, cursive;`,
  body: `'Philosopher', sans-serif;`,
  name: `BiankaScript, cursive;`,
  fullName: `'Philosopher', sans-serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
  },
  subHeading_lv2: {
    fontFamily: 'subHeading_lv2',
    fontSize: 'subHeading_lv2',
    fontWeight: 'subHeading_lv2',
    fontStyle: 'var(--fontItalic-subHeading_lv2)',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    textAlign: 'center',
  },
  nameProps: {
    fontFamily: 'name',
    fontSize: 'name',
    fontWeight: 'name',
    fontStyle: 'var(--fontItalic-name)',
  },
  fullNameProps: {
    fontFamily: 'fullName',
    fontSize: 'fullName',
    fontWeight: 'fullName',
    fontStyle: 'var(--fontItalic-fullName)',
  },
};

export const PRIMARY_BUTTON_PROPS = {
  size: 'sm',
  fontWeight: 'normal',
  bgColor: 'bgSecondary',
  color: 'btnMainColor',
  _active: { opacity: '0.9' },
  _hover: { opacity: '0.7' },
};

export const SECONDARY_BUTTON_PROPS = {
  ...PRIMARY_BUTTON_PROPS,
  bgColor: 'bgPrimary',
  color: 'mainColorText',
};
